html,
body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

input[type=text].hasError,
input[type=password].hasError,
input[type=number].hasError {
    border: 1px solid red;
}

.label-error {
    color: red;
}

:focus {
    outline: none !important;
}

app-root {
    display: block;
    margin: 0;
    min-height: 100%;
    float: left;
    width: 100%;
    position: relative;
}